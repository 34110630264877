// @flow
import React, { useEffect, useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import LanguageDropdown from "../language-dropdown/LanguageDropdown";
import logo from "../../assets/images/tutormio logo no background3.png";
import WishListDropDown from "../wishlist/WishListDropDown";

const NavBar = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lang, setLang] = useState(() => {
    import("./translation.js").then((module) => {
      return module[`${props.user.language}`];
    });
  });

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    import("./translation.js").then((module) => {
      setLang(module[`${props.user.language}`]);
    });
  }, [props.user.language]);

  function logOutHandler() {
    setExpanded((expanded) => !expanded);
    props.logOutUser();
  }
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
        className={
          scrollPosition >= 90
            ? "py-lg-3 sticky-top scrolled-bg"
            : "py-lg-3 sticky-top original-bg"
        }
      >
        <Container>
          {/* Logo */}
          <Navbar.Brand className="me-lg-5">
            <NavLink to="/">
              <img src={logo} alt="" className="logo-dark" height="30em" />
            </NavLink>
          </Navbar.Brand>
          {/* Language Dropdown */}
          <Navbar.Brand>
            <LanguageDropdown
              tunnelingLang={props.user.language}
              bubblingLang={props.bubblingLang}
            />
          </Navbar.Brand>

          {/* mobile view collapsed menu dropdown */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>

          {/* Home Courses Plans */}
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav as="ul" className="me-auto align-items-center">
              <Nav.Item as="li" className="mx-lg-1">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link"
                  }
                  onClick={() => setExpanded(false)}
                >
                  {lang?.home}
                </NavLink>
              </Nav.Item>
              <Nav.Item className="mx-lg-1">
                <NavLink
                  to="/courses"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link"
                  }
                  onClick={() => setExpanded(false)}
                >
                  {lang?.courses}
                </NavLink>
              </Nav.Item>
              <Nav.Item className="mx-lg-1">
                <NavLink
                  to="/plans"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link"
                  }
                  onClick={() => setExpanded(false)}
                >
                  {lang?.plans}
                </NavLink>
              </Nav.Item>
              {/* <Nav.Item className="mx-lg-1">
                <NavLink
                  to="/test"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active custom-nav-link"
                      : "nav-link custom-nav-link"
                  }
                  onClick={() => setExpanded(false)}
                >
                  Test
                </NavLink>
              </Nav.Item> */}
            </Nav>
          </Navbar.Collapse>

          {/* FAQs Contact */}
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <div className="my-right">
              <Nav as="ul" className="me-auto align-items-center">
                <Nav.Item className="mx-lg-1"></Nav.Item>
                <Nav.Item className="mx-lg-1">
                  <WishListDropDown
                    wishlist={props.user.wishlist}
                    activePlan={props.user.activePlan}
                    navigateToClass={props.navigateToClass}
                    clearWishList={props.clearWishList}
                    removeClass={props.removeClass}
                    removePlan={props.removePlan}
                    language={lang?.wishlist}
                  />
                </Nav.Item>
                <Nav.Item className="mx-lg-1">
                  <NavLink
                    to="/contact"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link active custom-nav-link"
                        : "nav-link custom-nav-link"
                    }
                    onClick={() => setExpanded(false)}
                  >
                    {lang?.contactUs}
                  </NavLink>
                </Nav.Item>
                <Nav.Item className="mx-lg-1"></Nav.Item>
                <Nav.Item className="mx-lg-1">
                  {props.user.isLoggedIn ? (
                    <NavLink
                      // to="/"
                      className=" custom-nav-link"
                      onClick={logOutHandler}
                    >
                      Logout
                    </NavLink>
                  ) : (
                    <NavLink
                      to="/login"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link active custom-nav-link"
                          : "nav-link custom-nav-link"
                      }
                      onClick={() => {
                        setExpanded((expanded) => false); //false
                      }}
                    >
                      Login
                    </NavLink>
                  )}
                </Nav.Item>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
