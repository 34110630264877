import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/Footer";
import "./root-layout.css";

function RootLayout(props) {
  return (
    <>
      <NavBar
        user={props.user}
        navigateToClass={props.navigateToClass}
        clearWishList={props.clearWishList}
        removeClass={props.removeClass}
        removePlan={props.removePlan}
        bubblingLang={props.bubblingLang}
        logOutUser={props.logOutUser}
      />
      <main className="my-root-layout">
        <Outlet />
      </main>
      <Footer language={props.user.language} />
    </>
  );
}
export default RootLayout;
