// import { jwtDecode } from "jwt-decode";
import { redirect } from "react-router-dom";

export function getAuthToken() {
  const token = localStorage.getItem("tutorMioToken");
  return token;
}

export function tokenLoader() {
  return getAuthToken();
}

export function checkAuthLoader() {
  const token = getAuthToken();
  if (!token) {
    return redirect("/");
  }
  return null;
}
